import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js";

export default class extends Controller {
    static targets = ["primary", "secondary"]

    connect() {
        this.setToggleButtonController()

        this.taskDashboard = document.getElementById('employee-zone--tasks--dashboard-wrapper')
        this.searchInput = document.getElementById('tasks_query_text_search_cont').value
        this.instance = new Mark(this.taskDashboard)
        this.instance.mark(this.searchInput, {
            className: 'bg-blue-light'
        })
    }

    setToggleButtonController() {
        const controllerName = 'components--form--toggle-button'

        this.toggleButtonController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
        this.toggleButtonController.updateButton()
    }
}
