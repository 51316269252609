import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        id: String
    }

    connect() {
        this.getCalculations()
    }

    getCalculations(){
        fetch(`/${window.currentWorkspaceSlug}/employee_zone/work_schedule/calculations/${this.idValue}`)
        .then(data => data.json()
        ).then(balances =>{
            this.buildResult(balances.overtime_balance, `overtime--balance--${this.idValue}`)
            this.buildResult(balances.rest_day_balance, `rest-day--balance--${this.idValue}`)
            this.buildResult(balances.vacation_balance, `vacation--balance--${this.idValue}`)
            this.buildResult(balances.holiday_balance, `holiday--balance--${this.idValue}`)
            this.addValues()
        })
    }

    buildResult(balance, className){
        let num = parseFloat(balance);
        let formattedBalance = isNaN(num) || num === 0 ? '0.00' : num.toFixed(2)
        const elements = document.getElementsByClassName(className)

        for (let element of elements){
            element.textContent = formattedBalance
        }
    }

    addValues() {
        this.calculateElementsByClassBeginn('overtime--balance--')
        this.calculateElementsByClassBeginn('rest-day--balance--')
        this.calculateElementsByClassBeginn('vacation--balance--')
        this.calculateElementsByClassBeginn('holiday--balance--')
    }

    calculateElementsByClassBeginn(className) {
        let querys = document.querySelectorAll('[class*="' + className + '"]')
        const uniqueElements = new Set();
        const filteredElements = [];
        for (let query of querys){
            const elementId = query.id;
            if(!uniqueElements.has(elementId)){
                uniqueElements.add(elementId);
                filteredElements.push(query);
            }
        }
        
        if (filteredElements.length > 0){
            let result = 0;
            for (let num of filteredElements){
                result = result + parseFloat(num.textContent);
            }
            let formattedBalance = isNaN(result) || result === 0 ? '0.00' : result.toFixed(2)
            let header = document.getElementById(`${className}total`)
            header.textContent = formattedBalance;
        }
    }
}
