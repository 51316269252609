import { Controller } from "@hotwired/stimulus"
import {get} from '@rails/request.js'

export default class extends Controller {
    static values = {workspaceSlug: String, employeeId: Number, contractId: Number }
    connect() {
        this.inputValue = document.getElementById('contract_contract_template_id').value
        document.addEventListener("selectDropdownSelected", this.onChange.bind(this));
    }
    async onChange(event){
        event.preventDefault();
        if(document.getElementById('contract_contract_template_id').value !== this.inputValue) {
            const contractTemplateId = this.element.querySelector('#contract_contract_template_id').value
            await get(`/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/general/${this.contractIdValue}/edit?contract_template_id=${contractTemplateId}`,  { responseKind: "turbo-stream" })
            this.inputValue = this.element.querySelector('#contract_contract_template_id').value
        }
    }
}