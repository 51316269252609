import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chat"
export default class extends Controller {
    static values = { messageId: String }

    scrollToMessage(event) {
        const messageId = this.messageIdValue; // Assuming the input has the message ID
        const messageElement = document.getElementById(messageId)

        if (messageElement) {
            messageElement.scrollIntoView({ behavior: "smooth", block: "center" });
            // Optionally, you can add a highlight effect here
            messageElement.style.transition = "background-color 1s ease";
            messageElement.classList.add("bg-canvas-light");
            setTimeout(() => {
                messageElement.style.backgroundColor = "transparent"; // Fade to transparent
            }, 1000); // Wait 1 second before starting fade out

            // Cleanup after the fade out completes
            setTimeout(() => {
                messageElement.style.transition = ""; // Reset transition
                messageElement.style.backgroundColor = "";
                messageElement.classList.remove("bg-canvas-light"); // Clear background color
            }, 2000); // Remove styles after 2 seconds
        }
    }
}