import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["numberInput"];

    connect() {
        this.syncInputLength();
    }

    syncInputLength() {
        this.numberInputTargets.forEach(inputField => {
            const input = inputField.querySelector('input');
            this.resizeField(input);
        });
    }

    onInput(event) {
        const input = event.target;
        this.resizeField(input);
    }

    parseAndConvertNumber(element) {
        let number = element.value.replace(/'/g, '')
        return !isNaN(Number(number)) ? Number(number) : 0
    }

    resizeField(input){
        input.value = this.parseAndConvertNumber(input);
        input.style.width = this.getRealWidth(input)+ 6 + 'px';
    }

    getRealWidth(input){
        const span = document.createElement('span');
        span.classList.add('invisible', 'absolute', 'whitespace-pre');
        span.style.font = window.getComputedStyle(input).font;
        span.textContent = input.value;
        document.body.appendChild(span);
        const textWidth = span.offsetWidth;
        document.body.removeChild(span);
        return textWidth;
    }
}
