import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainSection", "nestedFieldsWrapper", "childrenIndex"];
  static values = { openTaskTemplateFormIdentifier: String }

  connect() {
    console.log("Connected")
  }

  showTaskTemplateForm(event) {
    let identifier = event.currentTarget.dataset.identifier
    this.mainSectionTarget.classList.add("invisible")

    this.mainSectionTarget.childNodes.forEach((child) => {
      child.classList.add("hidden")
    })


    this.childrenIndexTargets.forEach((child) => {
      child.classList.add("hidden")
    })

    this.nestedFieldsWrapperTarget.classList.remove("hidden")

    this.openTaskTemplateFormIdentifierValue = identifier

    let taskForm = document.querySelector(`#employee-zone--work-schedule--shift-templates--new--task-template-wrapper-${identifier}`)
    taskForm.classList.remove("hidden")
  }


  closeTaskTemplateForm() {
    let taskForm = document.querySelector(`#employee-zone--work-schedule--shift-templates--new--task-template-wrapper-${this.openTaskTemplateFormIdentifierValue}`)
    this.mainSectionTarget.classList.remove("invisible")
    taskForm.classList.add("hidden")
    this.mainSectionTarget.childNodes.forEach((child) => {
      child.classList.remove("hidden")
    })

    this.childrenIndexTargets.forEach((child) => {
      child.classList.remove("hidden")
    })

    let taskTitle = document.querySelector(`#employee-zone--work-schedule--shift-templates--new--task-template-title-${this.openTaskTemplateFormIdentifierValue}`)
    let titleInput = document.querySelector(`#shift_template_task_templates_attributes_${this.openTaskTemplateFormIdentifierValue}_title`)

    taskTitle.textContent = titleInput.value
  }

  resetChecklistForm() {
    document.getElementById("calendar_entry_checklist_title").value = ""
    document.querySelectorAll(".remove-checklist-item-button").forEach((button) => { button.click() })
    document.querySelector("#components--employee-zone--work-schedule--shift-templates--new-checklist-form-back-button").click()
  }

  resetFormForm() {
    document.getElementById("calendar_entry_form_title").value = ""
    document.querySelectorAll(".remove-form-question-button").forEach((button) => { button.click() })
    document.querySelector("#components--employee-zone--work-schedule--shift-templates--new-form-form-back-button").click()
  }

  closeChecklistForm() {
    const element = document.querySelector("#components--employee-zone--work-schedule--shift-templates--new-checklist-form-open-button")
    const labelChecklistEmpty = element.dataset.labelChecklistEmpty
    const labelChecklistExists = element.dataset.labelChecklistExists
    const buttonLabel = document.querySelector("#components--employee-zone--work-schedule--shift-templates--new--add-checklist-item-button div").lastChild

    if (document.querySelector("#calendar_entry_checklist_title").value == "") {
      buttonLabel.textContent = labelChecklistEmpty
    } else {
      buttonLabel.textContent = labelChecklistExists
    }
  }

  closeFormForm() {
    const element = document.querySelector("#components--employee-zone--work-schedule--shift-templates--new-form-form-open-button")
    const labelFormEmpty = element.dataset.labelFormEmpty
    const labelFormExists = element.dataset.labelFormExists
    const buttonLabel = document.querySelector("#components--employee-zone--work-schedule--shift-templates--new--add-form-question-button div").lastChild

    if (document.querySelector("#calendar_entry_form_title").value == "") {
      buttonLabel.textContent = labelFormEmpty
    } else {
      buttonLabel.textContent = labelFormExists
    }
  }

}
