import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {
    static values = {workspaceSlug: String, employeeId: Number, contractId: Number, salaryReductionType: Number, hasQualifiedReductionOptions: Boolean, hasUnQualifiedReductionOptions: Boolean }

    connect() {
        this.inputValue = document.body.querySelector('#contract_salary_reduction_option_id').value
        this.contractSalaryReductionType =  document.body.querySelector('#contract_salary_reduction_type').value
        this.qualifiedElement = document.getElementById('qualified-employee-dropdown')
        this.unqualifiedElement = document.getElementById('unqualified-employee-dropdown')
    }
    onReductionTypeSelect() {
        document.getElementById('employee-zone--employees--show--contracts--general--edit--updatable-salary-reduction-description-wrapper').innerHTML = ''
        if (this.contractSalaryReductionType === '1') {
            this.qualifiedElement.classList.remove('hidden')
            this.unqualifiedElement.classList.add('hidden')
            if (!this.hasQualifiedReductionOptionsValue) {
                this.onReductionOptionSelect()
            }
        } else {
            this.qualifiedElement.classList.add('hidden')
            this.unqualifiedElement.classList.remove('hidden')
            if (!this.hasUnQualifiedReductionOptionsValue) {
                this.onReductionOptionSelect()
            }
        }
    }

    async onReductionOptionSelect() {
        let selectedValue;
        if (this.qualifiedElement.classList.contains('hidden')) {
           selectedValue = document.getElementById('contract_unqualified_salary_reduction_options').value
        } else {
            selectedValue = document.getElementById('contract_qualified_salary_reduction_options').value
        }
        let contractSalaryReductionOptionId = selectedValue
        if(contractSalaryReductionOptionId !== this.inputValue) {
            await get(`/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/salary/salary_reduction/${this.contractIdValue}/edit?contract_salary_reduction_option_id=${contractSalaryReductionOptionId}&contract_salary_reduction_type=${this.contractSalaryReductionType}&state=2`,  { responseKind: "turbo-stream" })
            this.inputValue = contractSalaryReductionOptionId
            document.body.querySelector('#contract_salary_reduction_option_id').value = contractSalaryReductionOptionId
        }
    }
}