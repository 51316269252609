import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["dropDownItem", "arrowUp", "arrowDown", "dropDownContent", "selectedObjectsContainer", "formInput"]
  static values = { placeholder: String, submitFormOnClose: Boolean, multiple: Boolean }

  connect() {
    this.updateSelectedObjects();

    if (this.multipleValue == true) {
      this.dropDownItemTargets.forEach((item) => {
        item.querySelectorAll("input[type='checkbox'], label").forEach((element) => {
          element.addEventListener('click', function (e) {
            e.stopPropagation();
          });
        });
      });
    }
  }

  handleDropdownClose(event) {
    if (this.submitFormOnCloseValue) {
      let form = this.formInputTarget.form;
      form.requestSubmit()
    }
  }

  toggleCheckbox(event) {
    let checkbox = event.currentTarget.querySelector("input[type='checkbox']") || event

    if (this.multipleValue == false) {
      let form = checkbox.form;
      if (form == undefined) {
        return
      }
      let allCheckboxes = form.querySelectorAll(`input[name='${this.formInputTarget.name}']`)
      allCheckboxes.forEach((element) => {
        if (element !== checkbox) {
          element.checked = false;
        }
      });
    }
    if (event.target.tagName !== "INPUT" && event.target.tagName !== "LABEL") {
      checkbox.checked = !checkbox.checked;
    }
    this.updateSelectedObjects();
  }

  updateSelectedObjects() {
    let updatedString = ""
    this.dropDownItemTargets.forEach((item) => {
      if (item.querySelector("input[type='checkbox']").checked) {
        updatedString += item.textContent.trim() + ", "
      }
    });
    if (updatedString.length == 0) {
      this.selectedObjectsContainerTarget.innerHTML = this.placeholderValue
    } else {
      this.selectedObjectsContainerTarget.innerHTML = updatedString.slice(0, -2)
    }
  }
}
