import { Controller } from "@hotwired/stimulus"
import MustacheHelper from "../../../../../helpers/mustache_helper"
import { DateTime } from 'luxon'
import {destroy, get} from "@rails/request.js";

export default class extends Controller {
    static targets = [
        'messageInput',
        'disabledSendButton',
        'enabledSendButton'
    ]

    static values = {
        streamChatChannelId: String,
        groupChatId: String
    }

    async connect() {
        this.setStreamChatClient()
        await this.setChannel()
        this.renderMessages()
        this.listenToAndRenderNewMessages()
        this.listenToMessageChanges()
        this.listenToSendMessageButton()
        this.updateChatListItemStyle()
        this.uploadedFiles = []
    }

    async disconnect() {
        for (const file of this.uploadedFiles) {
            await this.channel.deleteFile(file.asset_url)
        }
        this.channelEventListener.unsubscribe()
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async setChannel() {
        await this.chatIndexController.setCurrentChat(this.streamChatChannelIdValue);
        this.channel = await this.chatIndexController.fetchChannelById(this.streamChatChannelIdValue);
    }

    renderMessages() {
        let channelMessages = this.channel.state.messageSets[0].messages.slice()
        let messages = this.channel.state.messageSets[0].messages
        if (messages === channelMessages) {
            messages = messages.reverse()
        }
        messages.map((message) => { //is reversed because
            this.renderMessage(message)
        })
    }

    renderMessage(message) {
        // Render the main message template
        document.getElementById('channel-details-placeholder')?.remove()
        const messageElement = MustacheHelper.toElement(
            'components--employee-zone--chat--channel-details--index--mustache-template--message',
            {
                initials: [message.user.firstname.charAt(0), message.user.lastname.charAt(0)].join(''),
                senderName: message.user.name,
                sentAt: this.chatIndexController.messageSentAt(message).toLocaleString(DateTime.TIME_24_SIMPLE),
                message: message.html,
                messageId: message.id
            }
        );

        // Check if the message has attachments
        if (message.attachments && message.attachments.length > 0) {
            // Loop through each attachment and render its template
            message.attachments.forEach(attachment => {
                const attachmentElement = MustacheHelper.toElement(
                    'components--employee-zone--chat--channel-details--index--mustache-template--attached-file',
                    {
                        fileName: attachment.title,
                        fileUrl: attachment.asset_url,
                        fileSize: this.numberToHumanSize(attachment.file_size)
                    },
                    [],
                    ['---', '___']
                );
                // Append each attachment to the message element
                messageElement.querySelector('#attached-files-wrapper').appendChild(attachmentElement);
            });
        }

        // Prepend the message element to the DOM
        document.getElementsByClassName('channel-details-messages')[0].prepend(messageElement);
    }

    listenToAndRenderNewMessages() {
        this.channelEventListener = this.channel.on('message.new', event => {
            this.renderMessage(event.message)
        });
    }

    listenToMessageChanges() {
        this.messageInputTarget.addEventListener('keyup', (event) => {
            if (this.messageInputTarget.value.trim() === '') {
                this.disabledSendButtonTarget.classList.remove('hidden')
                this.enabledSendButtonTarget.classList.add('hidden')
            } else {
                this.disabledSendButtonTarget.classList.add('hidden')
                this.enabledSendButtonTarget.classList.remove('hidden')
            }

            // Listen for 'Enter' key press (without Shift) to send message
            if (event.key === 'Enter' && !event.shiftKey && this.messageInputTarget.value.trim() !== '') {
                event.preventDefault(); // Prevent default 'Enter' key behavior (e.g., new line)
                this.sendMessage(); // Send the message when 'Enter' is pressed
            }
        })
    }

    listenToSendMessageButton() {
        this.enabledSendButtonTarget.addEventListener('click', () => {
            this.sendMessage()
        })
    }

    sendMessage() {
        let body;
        if (this.uploadedFiles !== []) {
            body = {
                text: this.messageInputTarget.value,
                attachments: this.uploadedFiles.map(file => ({
                    type: file.type,
                    asset_url: file.asset_url,
                    title: file.title,
                    file_size: file.size
                }))
            }
        } else {
            body = { text: this.messageInputTarget.value }
        }
        this.channel.sendMessage(body);

        this.messageInputTarget.value = ''; // Clear the input field
        document.getElementById('file-wrapper').innerHTML = '';
        this.uploadedFiles = []

        this.disabledSendButtonTarget.classList.remove('hidden'); // Disable send button
        this.enabledSendButtonTarget.classList.add('hidden');     // Hide enabled button
    }

    updateChatListItemStyle() {
        let chatListItems = document.getElementsByClassName('chat-list-items')
        for (let i = 0; i < chatListItems.length; i++) {
            chatListItems[i].classList.remove('bg-canvas-white', 'border-l-primary', 'selected-chat')
            chatListItems[i].style = ''
        }
        let selectedChatListItem = document.getElementById(this.streamChatChannelIdValue)
        selectedChatListItem.classList.add('bg-canvas-white', 'border-l-primary', 'selected-chat')
        selectedChatListItem.style = 'border-left-width: 3px;'
    }

    async onFileUpload(event) {
        const uploadedFiles = event.target.files
        for (let i = 0; i < uploadedFiles.length; i++) {
            const response = await this.channel.sendFile(uploadedFiles[i])
            this.uploadedFiles.push({
                type: 'file',
                asset_url: response.file,
                title: uploadedFiles[i].name,
                size: uploadedFiles[i].size
            })
            this.displayUploadedFile(uploadedFiles[i], response.file)
        }
    }

    displayUploadedFile(file, filePath) {
        document.getElementById('file-wrapper').append(
            MustacheHelper.toElement(
                'components--employee-zone--chat--channel-details--index--mustache-template--uploaded-file',
                {
                    fileName: file.name,
                    fileSize: this.numberToHumanSize(file.size),
                    fileUrl: filePath
                }
            )
        )
    }

    async removeFile(event) {
        const assetUrl = event.target.getAttribute('data-file-url')
        const deletionSuccessful = await this.channel.deleteFile(assetUrl)
        if (deletionSuccessful) {
            this.uploadedFiles = this.uploadedFiles.filter(file => file.asset_url !== assetUrl);
            event.target.closest('.wrapper').remove()
        }
    }

    numberToHumanSize(size) {
        const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '0 Bytes';

        const index = Math.floor(Math.log(size) / Math.log(1024));
        const sizeInUnit = size / Math.pow(1024, index);
        return `${sizeInUnit.toFixed(2)} ${units[index]}`;
    }

    // Channel Actions
    muteChat() {
        console.log('mute')
    }

    archiveChat() {
        console.log('archive')
    }

    async editChat() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/chat/${this.groupChatIdValue}/edit?stream_chat_channel_id=${this.streamChatChannelIdValue}`, { responseKind: "turbo-stream" })
    }

    async deleteChat() {
        await destroy(`/${window.currentWorkspaceSlug}/employee_zone/chat/${this.streamChatChannelIdValue}`, { responseKind: "turbo-stream" })
    }
}