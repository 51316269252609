import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["eventName"];

    connect() {
        this.syncInputs();
    }

    syncInputs() {
        this.eventNameTargets.forEach(inputField => {
            const input = inputField.querySelector('input[type="text"]');
            if (input.value.trim() === "") {
                input.classList.add('bg-white', 'text-black');
                input.classList.remove('bg-blue', 'text-white');
            } else {
                input.classList.add('bg-blue', 'text-white');
                input.classList.remove('bg-white', 'text-black');
            }
        });
    }

    onChange(event) {
        const inputField = event.target;
        const value = inputField.value.trim();

        if (value === "") {
            inputField.classList.add('bg-white', 'text-black');
            inputField.classList.remove('bg-blue', 'text-white');
        } else {
            inputField.classList.add('bg-blue', 'text-white');
            inputField.classList.remove('bg-white', 'text-black');
        }
    }
}
