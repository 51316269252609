import { Controller } from "@hotwired/stimulus"
import IMask from 'imask';

export default class extends Controller {
    static targets = ["startHourInput", "startMinuteInput", "endHourInput", "endMinuteInput", "hiddenStartTimeInput", "hiddenEndTimeInput"];

    connect() {
        this.startHourInputTarget.addEventListener("input", (event) => this.handleInput(event, "startHour"));
        this.startMinuteInputTarget.addEventListener("input", (event) => this.handleInput(event, "startMinute"));
        this.endHourInputTarget.addEventListener("input", (event) => this.handleInput(event, "endHour"));
        this.endMinuteInputTarget.addEventListener("input", (event) => this.handleInput(event, "endMinute"));

        this.startHourInputTarget.addEventListener("keydown", (event) => this.handleArrowKeys(event, "startHour"));
        this.startMinuteInputTarget.addEventListener("keydown", (event) => this.handleArrowKeys(event, "startMinute"));
        this.endHourInputTarget.addEventListener("keydown", (event) => this.handleArrowKeys(event, "endHour"));
        this.endMinuteInputTarget.addEventListener("keydown", (event) => this.handleArrowKeys(event, "endMinute"));

        this.hiddenStartTimeInputTarget.addEventListener("change", () => this.emitTotalMinutesChange());
        this.hiddenEndTimeInputTarget.addEventListener("change", () => this.emitTotalMinutesChange());

        this.initializeMask(this.startHourInputTarget, this.startMinuteInputTarget, 23);
        this.initializeMask(this.startMinuteInputTarget, this.endHourInputTarget, 59);
        this.initializeMask(this.endHourInputTarget, this.endMinuteInputTarget, 23);
        this.initializeMask(this.endMinuteInputTarget, null, 59);

        this.emitTotalMinutesChange()
        this.observeNestedFormWrapper();
    }

    disconnect() {
        this.emitTotalMinutesChange()
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    initializeMask(currentInput, nextInput, max, type) {
        const maskOptions = {
            mask: '00',
            blocks: {
                '00': {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: max,
                },
            },
        };

        const mask = IMask(currentInput, maskOptions);
    }

    handleInput(event, type) {
        const value = event.target.value;
        if (value.length === 2) {
            switch (type) {
                case "startHour":
                    this.startMinuteInputTarget.focus();
                    break;
                case "startMinute":
                    this.endHourInputTarget.focus();
                    break;
                case "endHour":
                    this.endMinuteInputTarget.focus();
                    break;
            }
        }
    }

    handleArrowKeys(event, type) {
        const cursorPosition = event.target.selectionStart;
        const valueLength = event.target.value.length;

        if ((event.key === "ArrowLeft" && cursorPosition === 0) || (event.key === "ArrowRight" && cursorPosition === valueLength)) {
            event.preventDefault();
            switch (type) {
                case "startHour":
                    if (event.key === "ArrowLeft") {
                        // Optionally, focus previous element if exists
                    } else {
                        this.startMinuteInputTarget.focus();
                    }
                    break;
                case "startMinute":
                    if (event.key === "ArrowLeft") {
                        this.startHourInputTarget.focus();
                    } else {
                        this.endHourInputTarget.focus();
                    }
                    break;
                case "endHour":
                    if (event.key === "ArrowLeft") {
                        this.startMinuteInputTarget.focus();
                    } else {
                        this.endMinuteInputTarget.focus();
                    }
                    break;
                case "endMinute":
                    if (event.key === "ArrowLeft") {
                        this.endHourInputTarget.focus();
                    } else {
                        // Optionally, focus next element if exists
                    }
                    break;
            }
        }
    }

    totalMinutes() {
        const startTime = this.hiddenStartTimeInputTarget.value
        const endTime = this.hiddenEndTimeInputTarget.value
        const startMinutesAfterMidnight = this.timeStringToMinutes(startTime)
        const endMinutesAfterMidnight = this.timeStringToMinutes(endTime)
        const isOvernight = startMinutesAfterMidnight > endMinutesAfterMidnight

        if (isOvernight) {
            return 1440 - startMinutesAfterMidnight + endMinutesAfterMidnight
        } else {
            return endMinutesAfterMidnight - startMinutesAfterMidnight
        }
    }

    emitTotalMinutesChange() {
        this.element.dispatchEvent(new Event('totalMinutesChange', { bubbles: true }));
    }

    timeStringToMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }


    // For the work time summary stimulus controller to work properly, we need to make the stimulus outlet disconnect whenever a time range gets removed
    // In nested forms we just hide elements that we want to remove instead of removing them from the dom. For the work-time-summary calculations to work
    // properly, we need to manually remove the data-controller attribute from hidden elements, so the work time summary controller will treat them as "removed"
    observeNestedFormWrapper() {
        const nestedFormWrapper = this.element.closest('.nested-form-wrapper');

        if (!nestedFormWrapper) {
            return;
        }


        this.observer = new MutationObserver(() => {
            if (this.isHidden(nestedFormWrapper)) {
                this.element.removeAttribute('data-controller')
                this.disconnect();
            }
        });

        // Observe the ancestor for changes to the style attribute
        this.observer.observe(nestedFormWrapper, {
            attributes: true,
            attributeFilter: ["style"]
        });
    }

    isHidden(el) {
        return el.style.display === "none";
    }
}
