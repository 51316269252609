import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import IMask from 'imask'

export default class extends Controller {
    static values = {hasErrors: Boolean, withRange: Boolean}
    static targets = ["datepicker", "datepickerContainer", "arrowUp", "arrowDown", "startInput", "endInput", "outputContainer"]
    connect() {
        this.isInline = this.datepickerContainerTarget.getAttribute('data-is-always-open') === 'false' ? false : true;
        this.mode = this.withRangeValue === true ? 'range' : 'single'

        this.datepicker = flatpickr(this.datepickerTarget, {
            mode: this.mode,
            allowInput: true,
            dateFormat: "d.m.Y",
            locale: {
                "firstDayOfWeek": 1,
                "rangeSeparator": ' - '
            },
            inline: this.isInline,
            onMonthChange: this.updateMonthLabel.bind(this)
        });

        const inputElement = document.createElement("div");
        this.replaceDefaultYearPicker(inputElement)

        this.monthSelector = flatpickr(inputElement, {
            plugins: [new monthSelectPlugin({
                shorthand: true,
                dateFormat: "F, Y",
                altFormat: "F Y",
            })],
            onChange: this.monthSelectorOnClick.bind(this)
        })

        this.moveFlatpickrCalendar()
        this.setDate()
        if (this.hasErrorsValue) {
            this.setCustomStyles();
        }
        this.setCustomStyles()
        document.addEventListener('click', this.outsideClick.bind(this))
    }

    replaceDefaultYearPicker(inputElement) {
        // Find the flatpickr-month element
        let flatpickrMonth = document.querySelector("body > .flatpickr-calendar .flatpickr-month");
        if(flatpickrMonth === null) {
            flatpickrMonth =  this.datepickerContainerTarget.querySelector(".flatpickr-calendar .flatpickr-month");
        }
        this.flatpickrYearDropdown = document.querySelector("body > .flatpickr-calendar .flatpickr-current-month")
        if(this.flatpickrYearDropdown === null) {
            this.flatpickrYearDropdown =  this.datepickerContainerTarget.querySelector(".flatpickr-calendar .flatpickr-current-month")
        }
        this.flatpickrYearDropdown.style.display = 'none'

        // Create a new <input> element
        const containerElement = document.createElement("div")
        inputElement.setAttribute("data-components--layouts--datepicker-target", "monthSelect");
        inputElement.setAttribute("data-action", "click->components--layouts--datepicker#datepickerOnClick")
        inputElement.setAttribute('class', 'border border-transparent flex align-middle justify-center text-center text-base h-full font-bold')
        this.header = inputElement

        // Insert the <input> element as the first child of flatpickr-month
        containerElement.appendChild(inputElement)

        flatpickrMonth.insertBefore(containerElement, flatpickrMonth.firstChild);
    }
    moveFlatpickrCalendar() {
        const flatpickrCalendarElement = document.querySelector("body > .flatpickr-calendar");
        const inputElement = document.querySelector("body > .flatpickr-monthSelect-theme-light");
        this.outputContainerTarget.appendChild(flatpickrCalendarElement)
        this.outputContainerTarget.appendChild(inputElement)
    }

    // sets the Date if one is set currently it supports only one date
    setDate(){
        let dates = this.datepickerContainerTarget.getAttribute('data-dates')
        dates = [dates]
        if (dates !== null) {
            for (let i = 0; i < dates.length; i++) {
                if (dates[i] !== '' && dates[i] !== null) {
                    let tempDate = dates[i]
                    tempDate = tempDate.split('-')
                    this.datepicker.setDate(new Date(tempDate[0], tempDate[1]-1, tempDate[2]))
                }
                if (this.datepickerTarget.value !== '') {
                    if (this.isValidDateString(this.datepickerTarget.value, "d.m.Y")) {
                        let parsedDate = flatpickr.parseDate(this.datepickerTarget.value, "d.m.Y")
                        this.header.innerText = flatpickr.formatDate(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()),"F, Y")
                    }
                } else {
                    this.header.innerText = flatpickr.formatDate(new Date(), "F, Y")
                }
            }
        }
        if (this.datepickerTarget.value !== '') {
            if (this.isValidDateString(this.datepickerTarget.value, "d.m.Y")) {
                let parsedDate = flatpickr.parseDate(this.datepickerTarget.value, "d.m.Y")
                this.header.innerText = flatpickr.formatDate(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()),"F, Y")
            }
        } else {
            this.header.innerText = flatpickr.formatDate(new Date(), "F, Y")
        }
    }

    setCustomStyles(){
        if(this.isInline === false) {
            document.querySelectorAll('.flatpickr-calendar').forEach(calendar => {
                let outerTarget = calendar.closest('[data-controller="components--form--datepicker"]');
                if (outerTarget.getAttribute('data-is-always-open') === 'false') {
                    calendar.classList.add('custom-top');
                }
            });

            if (this.hasErrorsValue) {
                this.datepickerTarget.classList.add('custom-border')
            }
        } else {
            this.datepickerTarget.classList.add('hidden')
        }
    }

    isValidDateString(dateString, format) {
        try {
            const parsedDate = flatpickr.parseDate(dateString, format);
            return !isNaN(parsedDate.getTime());
        } catch (e) {
            return false;
        }
    }

    updateMonthLabel() {
        this.monthSelector.setDate(new Date(this.datepicker.currentYear, 1, 1))
        this.header.innerText = flatpickr.formatDate(new Date(this.datepicker.currentYear, this.datepicker.currentMonth, 1), "F, Y")
    }

    datepickerOnClick() {
        this.hideDatepicker()
        this.displayMonthSelector()
    }

    monthSelectorOnClick() {
        let currentYear = this.monthSelector.currentYear
        let currentMonth = this.monthSelector.currentMonth
        this.header.innerText = flatpickr.formatDate(new Date(currentYear, currentMonth, 1), "F, Y")
        this.datepicker.jumpToDate(new Date(currentYear, currentMonth, 1))
        this.hideMonthSelector()
        this.displayDatepicker()
    }

    onChange(event) {
        if(this.hasStartInputTarget) {
            if (this.mode === 'single') {
                let parts = this.datepickerTarget.value.split('.')
                let date = new Date(parts[2], parts[1] - 1, parts[0])
                this.startInputTarget.value = flatpickr.formatDate(date, "Y-m-d")
                if (date instanceof Date && !isNaN(date)) {
                    const customEvent = new CustomEvent("dateChanged", {
                        detail: { event },
                        bubbles: true
                    });
                    event.currentTarget.dispatchEvent(customEvent);
                }
            } else if(this.datepicker.selectedDates[0] !== undefined && this.datepicker.selectedDates[1] !== undefined) {
                this.startInputTarget.value = flatpickr.formatDate(this.datepicker.selectedDates[0], "Y-m-d")
                this.endInputTarget.value = flatpickr.formatDate(this.datepicker.selectedDates[1], "Y-m-d")
            }
        }
        if(this.hasOutputContainerTarget) {
            if (this.mode === 'single' || (this.datepicker.selectedDates[0] !== undefined && this.datepicker.selectedDates[1] !== undefined)) {
                this.outputContainerTarget.classList.add('hidden')
            }
        }
    }

    getScrollParent(node) {
        if (node == null) {
            return null;
        }
    
        if (node.scrollHeight > node.clientHeight) {
            return node;
        } else {
            return this.getScrollParent(node.parentNode);
        }
    }

    alignDatepicker() {
        this.outputContainerTarget.style.top = this.datepickerTarget.getBoundingClientRect().top + this.datepickerTarget.getBoundingClientRect().height + 'px'
    }

    onClick() {
        this.alignDatepicker()
        let scrollParent = this.getScrollParent(this.datepickerTarget)
        if(scrollParent !== null) {
            scrollParent.onscroll = () => { this.alignDatepicker()}
        }
        if(this.hasOutputContainerTarget) {
            this.outputContainerTarget.classList.remove('hidden')
        }
    }

    outsideClick(event) {
        if(!this.isInline){
            if (!this.element.contains(event.target)) {
                this.hideDatepicker();
            }
        }
    }

    displayDatepicker() {
        this.datepicker.open()
    }

    hideDatepicker() {
        if(this.hasOutputContainerTarget) {
            this.outputContainerTarget.classList.add('hidden')
        }
        this.datepicker.close()
    }

    displayMonthSelector() {
        this.monthSelector.open()
    }

    hideMonthSelector() {
        this.monthSelector.close()
    }

    disconnect() {
        if(this.hasMask) {
            this.mask.destroy();
        }
    }

}