import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        let inputElement = this.element.querySelector('input')
        if (inputElement.value !== '') {
            this.element.querySelector('input').classList.remove('w-[2.875rem]');
            this.element.querySelector('input').classList.add('w-full', 'pl-10');
        }
    }

    expand() {
        this.element.querySelector('input').classList.remove('w-[2.875rem]');
        this.element.querySelector('input').classList.add('w-full', 'pl-10');
    }

    shrink() {
        if (!this.element.querySelector('input').value) {
            this.element.querySelector('input').classList.remove('w-full', 'pl-10');
            this.element.querySelector('input').classList.add('w-[2.875rem]');
        }
    }
}