import { Controller } from "@hotwired/stimulus"
import Mark from 'mark.js'


export default class extends Controller {
    static targets = ["content", "search", "container"]

    connect() {
        const searchText = this.searchTarget.querySelector('input').value
        const inputTexts = this.contentTarget;

        this.instance = new Mark(inputTexts)
        this.instance.mark(searchText, {
            className: 'bg-blue-light'
        })
    }

    onInput(event) {
        event.preventDefault()

        const firstForm = this.containerTarget.querySelector('form');
        if (!firstForm) return;

        firstForm.requestSubmit()
    }
}
