import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static values = { selectedItem : String }
    static targets = [ "input", "value", "selectedIcon", "label", "arrowUp", "arrowDown", "itemContainer", "hiddenInput" ]

    connect() {
        if (this.selectedItemValue != null) {
            this.setSelected(this.selectedItemValue)
        }
        this.outsideClickListener = this.outsideClick.bind(this);
        document.addEventListener("itemSelected", this.handleSelect.bind(this));
        document.addEventListener("divScrolled", this.handleScroll.bind(this)); // A signal can be received here if, for example, a dropdown is located in a scrollable div
    }

    onClick() {
        this.selectButtonId = event.currentTarget.querySelector('input[type="hidden"]').id.replace(/_/g, "-")
        let existingDropdown = document.getElementById(`active-dropdown-${this.selectButtonId}`);
        if (existingDropdown) {
            this.hideDropdown()
        } else {
            this.showDropdown()
        }
    }

    showDropdown() {
        this.toggleArrows()
        let existingDropdown = document.getElementById(`active-dropdown-${this.selectButtonId}`);
        if(existingDropdown) return

        // this.isSelected();
        let dropdownContent = this.itemContainerTarget.cloneNode(true)
        let element = document.body.appendChild(dropdownContent);

        const buttonRect = this.inputTarget.getBoundingClientRect();
        const top = buttonRect.bottom + window.scrollY;
        const left = buttonRect.left + window.scrollX;

        element.style.position = 'absolute';
        element.style.width = this.inputTarget.offsetWidth
        element.style.zIndex = '1000';
        element.style.top = `${top}px`;
        element.style.left = `${left}px`;
        element.classList.remove("hidden");
        element.id = `active-dropdown-${this.selectButtonId}`
        enter(element);

        document.addEventListener('click', this.outsideClickListener);
        this.dropdownVisible = true; // Track that the dropdown is visible
    }

    hideDropdown() {
        this.toggleArrows()
        let element = document.getElementById(`active-dropdown-${this.selectButtonId}`);

        if (element) {
            leave(element);
            document.body.removeChild(element);
        }
        document.removeEventListener('click', this.outsideClickListener);
        this.dropdownVisible = false; // Track that the dropdown is hidden
    }

    toggleArrows(){
        this.arrowDownTarget.classList.toggle('hidden')
        this.arrowUpTarget.classList.toggle('hidden')
    }

    setSelected(selectedItemValue) {
        this.unselectAll()
        this.inputTarget.value = selectedItemValue
        // Accessing all targets as arrays
        const labels = this.labelTargets;
        const selectedIcons = this.selectedIconTargets;
        const values = this.valueTargets;

        // Looping through each label target
        labels.forEach((label, index) => {
            if (label.innerText === selectedItemValue) {
                selectedIcons[index].classList.remove('hidden');
                this.hiddenInputTarget.value = values[index].innerText;
            }
        });
    }

    unselectAll(){
        let items = this.itemContainerTarget.querySelectorAll('.dropdown-item')
        for (let i = 0; i < items.length; i++) {
            items[i].children[1].classList.add('hidden')
        }
    }

    handleSelect(event){
        let dropdownContentId = event.detail.event.currentTarget.closest('[data-components--form--select-dropdown-target="itemContainer"]').id
        let label = event.detail.event.currentTarget.querySelector('[data-components--form--select-dropdown-target="label"]').innerText
        let elements = this.itemContainerTarget.querySelectorAll('[data-components--form--select-dropdown-target="label"]');

        let selectedElement = null
        for (const element of elements) {
            if (element.innerText.trim() === label) {
                selectedElement = element;
                break;
            }
        }


        if (selectedElement) {
            selectedElement = selectedElement.closest('.dropdown-item')

            this.unselectAll()
            let labelTarget = selectedElement.querySelector('[data-components--form--select-dropdown-target="label"]');
            let selectedIconTarget = selectedElement.querySelector('[data-components--form--select-dropdown-target="selectedIcon"]');
            let valueTarget = selectedElement.querySelector('[data-components--form--select-dropdown-target="value"]');

            if(dropdownContentId.includes(this.selectButtonId)) {
                selectedIconTarget.classList.remove('hidden')
                this.inputTarget.value = labelTarget.innerText
                this.hiddenInputTarget.value = valueTarget.innerText
                this.toggleArrows()
                const selectDropdownSelectedEvent = new CustomEvent("selectDropdownSelected", {
                    bubbles: true, // This makes the event bubble up through the DOM
                });

                // Dispatch the event on the scrollableDivTarget so it bubbles up through the DOM
                document.dispatchEvent(selectDropdownSelectedEvent);
            }
        }
        this.toggleArrows()
    }

    outsideClick(event) {
        let element = document.getElementById(`active-dropdown-${this.selectButtonId}`)
        if (element) {
            if (element !== event.target && !this.element.contains(event.target)) {
                this.hideDropdown()
            }
        }
    }

    handleScroll() {
        if (!this.dropdownVisible) return;

        const dropdown = document.getElementById(`active-dropdown-${this.selectButtonId}`);
        if (!dropdown) return;
        this.hideDropdown()
    }

    disconnect() {
        this.hideDropdown()
        document.removeEventListener('click', this.outsideClickListener);
        document.removeEventListener("sideBarScrollDetected", this.handleScroll.bind(this));
    }
}