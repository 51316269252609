import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import countryCodeEmoji from 'country-code-emoji'
import IMask from 'imask'

export default class extends Controller {
    static values = {hasErrors: Boolean}
    static targets = [ 'input', 'data', 'phoneNumberContainer', 'arrowContainer' ]

    connect() {
        const input = this.inputTarget;
        this.maskOptions = {
            mask: /^[0-9\-()+\s]*$/,
        };

        this.mask = IMask(input, this.maskOptions);
        this.intlTelInput = intlTelInput(input, {
            utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.5.3/build/js/utils.js',
            hiddenInput: 'phone',
            initialCountry: 'ch',
            preferredCountries: ['ch', 'de', 'at'],
            nationalMode: true,
            separateDialCode: true,
            autoPlaceholder: 'aggressive'
        });
        this.container = this.inputTarget.parentElement
        this.setFlags()
        this.setNumber()
        this.setArrows()
        this.setInputPadding()
        this.clickOutsideListener = this.handleClickOutside.bind(this)
        window.addEventListener("click", this.clickOutsideListener);
        this.arrows = this.container.querySelector('.iti__arrow').children[0]

        if (this.hasErrorsValue) {
            this.addButtonStyle();
        }
    }

    setFlags() {
        let countryData = window.intlTelInputGlobals.getCountryData()
        let iso2, content
        for (let i = 0; i < countryData.length; i++) {
            iso2 = countryData[i].iso2
            content = countryCodeEmoji(iso2.toUpperCase())

            const style = document.createElement('style');
            document.head.appendChild(style);
            style.sheet.insertRule(`.iti__${iso2}::before { content: '${content}'; }`);
        }
    }

    setNumber() {
        const phoneNumber = this.inputTarget.getAttribute('data-phone-number')
        if (phoneNumber !== 0 && phoneNumber !== '' && phoneNumber !== null) {
            this.intlTelInput.setNumber(phoneNumber)
        }
    }

    setArrows() {
        const arrowDownElement = this.container.querySelector('.iti__arrow')
        let customArrows = this.arrowContainerTarget;
        arrowDownElement.appendChild(customArrows)
    }

    setInputPadding() {
        this.inputTarget.classList.add('custom-phone_number-padding')
    }

    handleClickOutside(event) {
        const countryList = this.container.querySelector('.iti__dropdown-content')
        if(countryList.classList.contains('iti__hide')) {
            this.resetInputStyle()
            this.setArrowDown()
        }

        if(document.activeElement !== this.inputTarget && document.activeElement !== this.container.querySelector('.iti__selected-flag')) {
            this.removeButtonStyle()
        }
    }

    resetInputStyle() {
        const selectedFlag = this.container.querySelector('.iti__selected-flag')
        selectedFlag.classList.remove('custom-rounded-bl', 'custom-rounded-tl')
        this.inputTarget.classList.remove('rounded-b-none', 'rounded-t-none')
        this.inputTarget.classList.add('rounded-lg')
    }

    countryIpLookUp() {
        let callback;
        fetch("https://ipapi.co/json")
            .then(function(res) { return res.json(); })
            .then(function(data) { callback(data.country_code); })
            .catch(function() { callback("ch"); });

    }

    onDropdown() {
        const countryList = this.container.querySelector('.iti__country-list')

        if(countryList && !countryList.classList.contains('iti__hide')) {
           this.addButtonStyle()
            this.setArrowUp()
        }
    }

    addButtonStyle() {
        let ringColor
        let borderColor
        if (this.hasErrorsValue) {
            borderColor = 'border-red'
        } else {
            borderColor = 'border-primary'
        }

        this.phoneNumberContainerTarget.classList.remove('border-transparent')
        this.phoneNumberContainerTarget.classList.add(borderColor)
        const selectedFlag = this.container.querySelector('.iti__selected-flag')
        selectedFlag.classList.add('white-background', borderColor)
        this.inputTarget.classList.add('bg-canvas-white')

    }

    removeButtonStyle() {
        if (!this.hasErrorsValue) {
            this.phoneNumberContainerTarget.classList.remove('border-primary')
            this.phoneNumberContainerTarget.classList.add('border-transparent')
            const selectedFlag = this.container.querySelector('.iti__selected-flag')
            selectedFlag.classList.remove('white-background', 'border-primary')
            this.inputTarget.classList.remove('bg-canvas-white')
        }
    }

    setArrowUp() {
        this.arrows.children[0].classList.add('hidden')
        this.arrows.children[1].classList.remove('hidden')
    }

    setArrowDown() {
        this.arrows.children[0].classList.remove('hidden')
        this.arrows.children[1].classList.add('hidden')
    }

    onClick() {
        const countryList = this.container.querySelector('.iti__country-list')
        if(!countryList.classList.contains('iti__hide')) {
            this.intlTelInput._closeDropdown()
        }
        this.addButtonStyle()
        this.setArrowUp()
    }

    onChange() {
        // Sets the phone number with area code as value of the phone number inputField
        this.dataTarget.value = this.intlTelInput.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL)
    }

    disconnect() {
        document.removeEventListener("click", this.clickOutsideListener);
        this.intlTelInput.destroy()
        super.disconnect()
    }
}
