import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["outer", "content", "modal"]

    connect() {
        this.element.addEventListener('turbo:frame-render', () => {
            this.adjustModalVisibility();
        });
    }

    adjustModalVisibility() {
        const outerDiv = this.outerTarget;
        const contentDiv = this.contentTarget;
        const modalDiv = this.modalTarget;
        modalDiv.classList.add('hidden');

        if (contentDiv.scrollHeight > outerDiv.clientHeight) {
            modalDiv.classList.remove('hidden');
        }
    }
}