import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static outlets = ["components--form--time-range"]
    static targets = [
        "totalHours",
        "totalMinutes",
        "workHours",
        "workMinutes",
        "breakHours",
        "breakMinutes"
    ]

    connect() {
        this.setSummary()
        document.addEventListener('totalMinutesChange', () => {
            this.setSummary()
        });
    }

    componentsFormTimeRangeOutletConnected(outlet, element) {
        this.setSummary()
    }

    componentsFormTimeRangeOutletDisconnected(outlet, element) {
        this.setSummary()
    }

    setSummary() {
        let totalMinutes = 0
        let breakMinutes = 0

        let outletElements = this.componentsFormTimeRangeOutletElements

        outletElements.forEach((outletElement) => {
            // For some reason (probably a bug from stimulus) I get weird behaviour when i directly call this.componentsFormTimeRangeOutlets.
            // That's why I call the outlet elements and then get the controller for each individually. This way everything works as expected.
            const controller = this.application.getControllerForElementAndIdentifier(
                outletElement,
                'components--form--time-range'
            )

            if (controller !== null) {
                const controllerMinutes = controller.totalMinutes()
                if (!isNaN(controllerMinutes)) {
                    const summaryWrapper = outletElement.closest('[data-work-time-summary-type]')

                    switch (summaryWrapper.dataset.workTimeSummaryType) {
                        case 'total':
                            totalMinutes += controllerMinutes
                            break;
                        case 'break':
                            breakMinutes += controllerMinutes
                            break;
                    }
                }
            }

        })

        let workMinutes = totalMinutes - breakMinutes

        this.totalHoursTarget.innerText = this.hoursAndMinutes(totalMinutes).hours
        this.totalMinutesTarget.innerText = this.hoursAndMinutes(totalMinutes).minutes

        this.workHoursTarget.innerText = this.hoursAndMinutes(workMinutes).hours
        this.workMinutesTarget.innerText = this.hoursAndMinutes(workMinutes).minutes

        this.breakHoursTarget.innerText = this.hoursAndMinutes(breakMinutes).hours
        this.breakMinutesTarget.innerText = this.hoursAndMinutes(breakMinutes).minutes
    }

    hoursAndMinutes(totalMinutes) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = (totalMinutes % 60).toString().padStart(2, '0');  // Ensure two digits for minutes
        return { hours, minutes };
    }
}
