import { Controller } from "@hotwired/stimulus"
import MustacheHelper from "../../../../../helpers/mustache_helper"
import { DateTime } from 'luxon'

export default class extends Controller {
    static targets = [
        'element'
    ]

    static values = {
        streamChatChannelId: String,
    }

    async connect() {
        this.setStreamChatClient()
        await this.setChannel()
        // this.renderNewGroupChat()
        this.elementTarget.remove()
        this.channelEventListener.unsubscribe()
    }

    disconnect() {
        this.channelEventListener.unsubscribe()
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async setChannel() {
        await this.chatIndexController.setCurrentChat(this.streamChatChannelIdValue);
        this.channel = this.chatIndexController.channels.find(channel => channel.id === this.streamChatChannelIdValue);
    }

    renderNewGroupChat() {
        const otherMembers = this.chatIndexController.otherMembers(this.channel)
        this.chatIndexController.displayChats(document.getElementsByClassName('channel-list')[0], otherMembers, this.channel, this.channel.id)
    }

}
