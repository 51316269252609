import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import luxonPlugin from '@fullcalendar/luxon3'
import { DateTime } from 'luxon'
import camelcaseKeys from 'camelcase-keys'
import { get } from '@rails/request.js'
import MustacheHelper from "../../../../helpers/mustache_helper"

export default class extends Controller {
    static targets = ['calendarWrapper']
    static values = {
        departmentIds: Array,
        locationIds: Array
    }

    connect() {
        this.loadAllData().then(() => {
            this.setupCalendar();
            this.setupDatepicker();
            this.setupLocationFilter();
            this.setupDepartmentFilter();
            this.setupTodayButton();
            this.setupCalendarFormatter();
            this.setupMyCalendarButton();
            this.calendar.render();
        })
    }

    loadAllData = async () => {
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries.json`, 0);
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/shifts.json`, 1);
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/absences.json`, 2);
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/schedules.json`, 3);
    }

    loadingQuery = async (url, type) => {
        let fetchResponse = await get(url);
        if (fetchResponse.ok) {
            const data = await fetchResponse.response.json();
            if (type == 0) this.calendarEntriesResponce = camelcaseKeys(data, { deep: true });
            if (type == 1) this.shiftResponce = camelcaseKeys(data, { deep: true });
            if (type == 2) this.absenceResponce = camelcaseKeys(data, { deep: true });
            if (type == 3) this.scheduleResponce = camelcaseKeys(data, { deep: true });
            console.log(this.scheduleResponce)
        }
    }

    eventReloaderTargetConnected(_eventReloader) {
        this.reloadEvents()
    }

    setupCalendar = () => {
        this.calendar = new Calendar(this.calendarWrapperTarget, {
            ...this.calendarBaseOptions(),
            ...this.calendarEventOptions(),
            ...this.calendarHeaderOptions(),
        })
    }

    setupDatepicker = () => {
        const datepickerWrapper = document.getElementById('calendar-datepicker-wrapper')
        const datepickerInput = datepickerWrapper.querySelector('input')
        datepickerInput.addEventListener('change', (event) => {
            const date = DateTime.fromFormat(event.target.value, 'dd.MM.yyyy')
            this.calendar.gotoDate(date.toISODate())
        })
    }

    setupTodayButton = () => {
        const button = document.getElementById('today-button');
        button.addEventListener('click', (event) => {
            this.calendar.today();
            const today = DateTime.now().toFormat('dd.MM.yyyy');
            const datepickerWrapper = document.getElementById('calendar-datepicker-wrapper');
            const datepickerInput = datepickerWrapper.querySelector('input');
            datepickerInput.value = today;
        })
    }

    setupMyCalendarButton = () => {
        const button = document.getElementById('my-calendar-button');
        this.currentEmployee = button.dataset.employeeId;
        const toggleInput = button.querySelector('input[name="toggle-active"]');
        this.filterByUser = toggleInput.value;
        button.addEventListener('click', (event) => {
            this.filterByUser = toggleInput.value;
            this.filterData();
        })
    }

    setupLocationFilter = () => {
        this.findDropDownElements('calendar--location-search--wrapper', 0)
    }

    setupDepartmentFilter = () => {
        this.findDropDownElements('calendar--department-search--wrapper', 1)
    }

    findDropDownElements = (id, isLocation) => {
        const dropDownWrapper = document.getElementById(id)
        const allInputs = dropDownWrapper.querySelectorAll('input');
        allInputs.forEach(input => {
            const listItem = input.parentNode.parentNode;
            this.setupEvent(input, input, 'change', isLocation)
            this.setupEvent(listItem, input, 'click', isLocation)
        })
    }

    setupEvent = (target, input, eventType, isLocation) => {
        target.addEventListener(eventType, (event) => {
            if (isLocation == 0) {
                this.locationIdsValue = this.updateList(this.locationIdsValue, input)
                this.filterData();
            }
            else if (isLocation == 1) {
                this.departmentIdsValue = this.updateList(this.departmentIdsValue, input)
                this.filterData();
            }
            else {
                //TODO DayView here
            }
        })
    }

    setupCalendarFormatter = () => {
        const target = document.getElementById('calendar--format--wrapper');
        const allInputs = target.querySelectorAll('input')
        allInputs.forEach(input => {
            const listItem = input.parentNode.parentNode;
            this.setupEvent(input, input, 'change', 2)
            this.setupEvent(listItem, input, 'click', 2)
        })
    }

    updateList = (list, input) => {
        return input.checked ? [...list, input.value] : list.filter(id => id != input.value);
    };

    calendarBaseOptions = () => ({
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',

        plugins: [interactionPlugin, luxonPlugin, timeGridPlugin],

        initialView: 'timeGridWeek',
        initialDate: DateTime.now().startOf('week').toISODate(),
        duration: { days: 7 },
        slotDuration: { hours: 1 },
        firstDay: 1, // Start the week on monday

        headerToolbar: false,

        contentHeight: 'auto',

        // TODO handle scrolling better
        stickyHeaderDates: true,
    })

    calendarEventOptions = () => ({
        eventSources: [
            this.absenceResponce,
            this.calendarEntriesResponce,
            this.shiftResponce,
            this.scheduleResponce
        ],
        eventDataTransform: function (eventData) {
            return camelcaseKeys(eventData, { deep: true })
        },

        eventClassNames: 'bg-transparent border-0',

        eventContent: (eventArgument) => {
            const templateValues = eventArgument.event.extendedProps?.templateValues
            return this.sortData(templateValues)
        },

        eventClick: async function (argument) {
            const calendarEntryId = argument.event.id.replace(/\D/g, '')
            await get(`/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries/${calendarEntryId}`, { responseKind: "turbo-stream" })
        }
    })

    calendarHeaderOptions = () => ({
        dayHeaderContent: (arg) => {
            const templateId = 'components--employee-zone--calendar--index--day-header'
            const selectedDateTime = DateTime.fromJSDate(arg.date)
            const currentDateTime = DateTime.fromJSDate(new Date())
            const placeholders = {
                dayOfMonth: selectedDateTime.toFormat('dd'),
                dayOfWeek: selectedDateTime.toFormat('ccc'),
                isCurrentDay: selectedDateTime.hasSame(currentDateTime, 'day')
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },
        slotLabelContent: (arg) => {
            const templateId = 'components--employee-zone--calendar--index--slot-label'
            const selectedDateTime = DateTime.fromJSDate(arg.date)
            const placeholders = {
                // TODO: I did a quick fix to not show the hour when it's 00:00
                // It's tricky to display it above the other content (z-index seems not to work)
                time: selectedDateTime.hour !== 0 ? selectedDateTime.toFormat('HH:mm') : '',
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },
        allDayContent: false
    })

    shiftElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--shift'

        const shiftStartTime = this.formatTime(templateValues.shiftStart)
        const shiftEndTime = this.formatTime(templateValues.shiftEnd)
        templateValues.shiftRange = `${shiftStartTime} - ${shiftEndTime}`
        templateValues.durationHours = (templateValues.durationSeconds / 3600).toFixed(2)

        templateValues.breakRanges = templateValues.shiftBreaks.map((shiftBreak, index, shiftBreaks) => {
            const breakStartTime = this.formatTime(shiftBreak.breakStart)
            const breakEndTime = this.formatTime(shiftBreak.breakEnd)
            let breakRange = `${breakStartTime} - ${breakEndTime}`
            if (index !== shiftBreaks.length - 1) {
                breakRange += ', '
            }
            return breakRange;
        })

        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'

        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColor
            },
            {
                target: '.published-background-color-target',
                style: 'backgroundColor',
                value: templateValues.published ? templateValues.publishedBackgroundColor : 'white'
            }
        ]

        return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }
    }

    absenceElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--absence'
        templateValues.publishedBackgroundClass = templateValues.published ? 'bg-canvas-darker' : 'bg-white'
        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'
        const placeholders = { ...templateValues }
        const styles = []
        const absenceFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        const iconTemplateId = `components--employee-zone--calendar--index--absence-icon-${templateValues.iconName}`
        absenceFragment.querySelector('.icon-wrapper').innerHTML = document.getElementById(iconTemplateId).innerHTML
        return { domNodes: [absenceFragment] }
    }

    scheduleElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--schedule'
        const scheduleStartTime = this.formatTime(templateValues.startTime)
        const scheduleEndTime = this.formatTime(templateValues.endTime)
        templateValues.scheduleRange = `${scheduleStartTime} - ${scheduleEndTime}`
        const placeholders = { ...templateValues }
        const styles = []
        const scheduleFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        return { domNodes: [scheduleFragment] }
    }

    calendarEntryElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--calendar-entry'
        templateValues.isTask = templateValues.entryType === 'task'
        templateValues.acceptedBackgroundClass = (templateValues.participationState === 'accepted' || templateValues.isTask) ? 'background-color-target' : 'bg-white'
        templateValues.acceptanceStateClass = templateValues.participationState
        templateValues.textColorClass = ['bg-blue', 'bg-green'].includes(templateValues.acceptedBackgroundClass) ? 'text-canvas-white' : 'text-ink'
        const calendarEntryStartTime = this.formatTime(templateValues.startTime)
        const calendarEntryEndTime = this.formatTime(templateValues.endTime)
        templateValues.calendarEntryRange = `${calendarEntryStartTime} - ${calendarEntryEndTime}`
        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColorHex
            },
            {
                target: '.text-color-target',
                style: 'color',
                value: templateValues.textColorHex
            },
        ]

        const calendarEntryFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        return { domNodes: [calendarEntryFragment] }
    }

    sortData(templateValues) {
        if (!templateValues) {
            return { domNodes: [] }
        } else if (templateValues.entryType === 'shift') {
            return this.shiftElement(templateValues);
        } else if (templateValues.entryType === 'absence') {
            return this.absenceElement(templateValues);
        } else if (['meeting', 'event', 'internal_event', 'task'].includes(templateValues.entryType)) {
            return this.calendarEntryElement(templateValues);
        } else if (templateValues.entryType === 'schedule') {
            return this.scheduleElement(templateValues)
        }
    }

    formatTime = (time) => (DateTime.fromObject({
        hour: time.hour,
        minute: time.minute
    }).toLocaleString(DateTime.TIME_24_SIMPLE))

    filterData() {
        this.filterShifts();
        this.filterCalenderEntries();
        this.filterAbsences();
        this.calendar.removeAllEventSources()

        this.calendar.addEventSource(this.shiftResponce)
        this.calendar.addEventSource(this.absenceResponce)
        this.calendar.addEventSource(this.calendarEntriesResponce)
        this.calendar.addEventSource(this.scheduleResponce)

    }

    filterCalenderEntries() {
        if (!this.allEntries) {
            this.allEntries = this.calendarEntriesResponce
        }
        this.calendarEntriesResponce = this.allEntries
        if (this.departmentIdsValue.length > 0) {
            this.calendarEntriesResponce = this.calendarEntriesResponce.filter(item => {
                return item.templateValues.departments.some(department => {
                    const departmentIdAsString = String(department.id);
                    return this.departmentIdsValue.includes(departmentIdAsString);
                });
            });
        }
        if (this.locationIdsValue.length > 0) {
            this.calendarEntriesResponce = this.calendarEntriesResponce.filter(item => {
                return item.templateValues.locations.some(location => {
                    const locationIdAsString = String(location.id);
                    return this.locationIdsValue.includes(locationIdAsString);
                });
            });
        }
        if (this.filterByUser == 1) {
            this.calendarEntriesResponce = this.calendarEntriesResponce.filter(item => {
                return item.templateValues.employees.some(employee => {
                    const employeeIdAsString = String(employee.id);
                    return this.currentEmployee == employeeIdAsString;
                });
            });
        }
    }

    filterShifts() {
        if (!this.allShifts) {
            this.allShifts = this.shiftResponce
        }

        this.shiftResponce = this.allShifts
        if (this.departmentIdsValue.length > 0) {
            this.shiftResponce = this.shiftResponce.filter(item => {
                const departmentIdAsString = String(item.templateValues.departmentId);
                return this.departmentIdsValue.includes(departmentIdAsString);
            });
        }
        if (this.locationIdsValue.length > 0) {
            this.shiftResponce = this.shiftResponce.filter(item => {
                const locationIdAsString = String(item.templateValues.locationId);
                return this.locationIdsValue.includes(locationIdAsString);
            });
        }
        if (this.filterByUser == 1) {
            this.shiftResponce = this.shiftResponce.filter(item => {
                const employeeIdAsString = String(item.templateValues.employeeId);
                return this.currentEmployee == employeeIdAsString;
            });
        }
    }

    filterAbsences() {
        if (!this.allAbsences) {
            this.allAbsences = this.absenceResponce
        }

        this.absenceResponce = this.allAbsences
        if (this.filterByUser == 1) {
            this.absenceResponce = this.absenceResponce.filter(item => {
                const employeeIdAsString = String(item.templateValues.employeeId);
                return this.currentEmployee == employeeIdAsString;
            });
        }
    }

    reloadEvents = (event) => {
        this.loadAllData().then(() => {
            this.filterData();
        })
    }
}
