import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['form', 'isApprovedInput', 'approveButton', 'rejectButton']

    connect() {
        this.approveButtonTarget.addEventListener('click', () => {
            this.isApprovedInputTarget.value = true
            this.formTarget.requestSubmit()
        })

        this.rejectButtonTarget.addEventListener('click', () => {
            this.isApprovedInputTarget.value = false
            this.formTarget.requestSubmit()
        })
    }
}
