
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["closeButton"]
    static values = { isAlwaysOpen: Boolean}
    connect() {
        let inputElement = this.element.querySelector('input')
        if (inputElement.value !== '') {
           this.expand()
        }
    }

    expand() {
        this.element.querySelector('input').classList.remove('w-[2.875rem]');
        this.element.querySelector('input').classList.add('w-full', 'pl-10');
        this.closeButtonTarget.classList.remove('hidden')
    }

    shrink() {
        if (!this.element.querySelector('input').value && !this.isAlwaysOpenValue) {
            this.closedStyles()
        }
    }

    closedStyles() {
        this.element.querySelector('input').value = ''
        this.element.querySelector('input').classList.remove('w-full', 'pl-10');
        this.element.querySelector('input').classList.add('w-[2.875rem]');
        this.closeButtonTarget.classList.add('hidden')
    }
}