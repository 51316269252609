import { Controller } from "@hotwired/stimulus"
import { StreamChat } from '../../../../libraries/stream_chat/library';
import MustacheHelper from "../../../../helpers/mustache_helper";
import { DateTime } from 'luxon'
import Mark from 'mark.js'
export default class extends Controller {
    static values = {
        streamChatApiKey: String,
        streamChatUserId: String,
        streamChatUserToken: String
    }

    static targets = ["closeButton"]

    async connect() {
        await this.initializeStreamChatClient()
        await this.reloadChannelList()

        this.streamChatClient.on(
            'notification.added_to_channel',
            event => this.reloadChannelList()
        )

        this.groupChatButton = document.getElementById('group-chat-creation-btn')
        this.chatSortWrapper = document.getElementById('chat-sort-dropdown-wrapper')
        this.channelList = document.getElementById('channel-list')
    }

    async initializeStreamChatClient() {
        this.streamChatClient = StreamChat.getInstance(this.streamChatApiKeyValue);

        await this.streamChatClient.connectUser(
            {id: this.streamChatUserIdValue},
            this.streamChatUserTokenValue
        );
    }

    async reloadChannelList() {
        await this.fetchRightSidebarChannels();
        this.renderChannelList(document.getElementsByClassName('channel-list')[0]);
    }

    expand() {
        this.groupChatButton.classList.add('hidden')
        this.chatSortWrapper.classList.add('hidden')
        this.channelList.classList.add('hidden')
    }

    shrink() {
        if (!this.element.querySelector('input').value) {
            this.closedStyles()
        }
    }

    closedStyles() {
        document.getElementById('search-channel-list').innerHTML = ''
        this.groupChatButton.classList.remove('hidden')
        this.chatSortWrapper.classList.remove('hidden')
        this.channelList.classList.remove('hidden')
    }

    async fetchRightSidebarChannels() {
        this.channelEventListeners?.forEach(listener => listener.unsubscribe());
        this.channelEventListeners = [];

        this.channels = await this.fetchChannels()

        this.channelEventListeners = this.channels.map(channel => {
            return channel.on('message.new', event => this.reloadChannelList())
        })
    }

    async fetchChannels(searchTerm= '') {
        let sort = [{last_message_at: -1}];
        let filter = {type: 'messaging', members: {$in: [this.streamChatUserIdValue]}, last_message_at: { $exists: true }};
        if (searchTerm) {
            filter = {
                type: 'messaging',
                members: {$in: [this.streamChatUserIdValue]},
                $or: [
                    { name: { $autocomplete: searchTerm } },
                    { 'member.user.name': { $autocomplete: searchTerm } },
                    { hidden: true },  // Include hidden channels
                    { hidden: false }  // Include visible channels
                ]
            };
        }

        let channels =  await this.streamChatClient.queryChannels(filter, sort, {
            watch: true,
            state: true,
            limit: 30,
            message_limit: 300
        });

        return channels
    }

    async fetchChannelById(channel_id){
        const filter = {
            id: { $eq: channel_id },
            $or: [
                { hidden: true },  // Include hidden channels
                { hidden: false }  // Include visible channels
            ]
        };
        const fetchedChannel = await this.streamChatClient.queryChannels(filter, {}, { limit: 1 });
        return fetchedChannel[0]
    }

    async fetchMessages(searchTerm = '', channelId = null) {
        if (!searchTerm) return [];

        let channelFilter = {};
        if (channelId) {
            // If channelId is provided, search within that specific channel
            channelFilter = { id: channelId };
        } else {
            // If no channelId is provided, search globally across channels where the user is a member
            channelFilter = { members: { $in: [this.streamChatUserIdValue] } };
        }

        const messageFilter = { text:  { "$autocomplete": searchTerm}}

        const searchResults = await this.streamChatClient.search(
            channelFilter,                   // Search filter (either for a specific channel or globally)
            messageFilter,               // The search term to look for in the message content
            { limit: 100, offset: 0 }          // Adjust the limit as needed
        );

        // Extract and return messages from the search results
        const messages = searchResults.results.map(result => result.message);
        return messages;
    }

    async onSearchInput() {
        const searchTerm = this.element.querySelector('input').value.toLowerCase();
        let searchListItemWrapper = document.getElementById('search-channel-list');

        this.filteredChannels = await this.fetchChannels(searchTerm);

        searchListItemWrapper.innerHTML = '';

        // Render the filtered list
        this.filteredChannels.forEach(channel => {
            const otherMembers = this.otherMembers(channel);
            this.displayChats(searchListItemWrapper, otherMembers, channel, '');
        });

        // Apply highlighting to the search term
        this.mark = new Mark(searchListItemWrapper);
        this.mark.mark(searchTerm, {
            className: 'bg-blue-light'
        });
    }

    async setCurrentChat(selectedChannelId) {
        // Fetch the selected channel
        const newChannel = await this.fetchChannelById(selectedChannelId)

        const messageResponse = await newChannel.query({
            messages: { limit: 1 } // Get at least one message
        });

        // Check if the channel is already in the list
        const existingChannelIndex = this.channels.findIndex(channel => channel.id === newChannel.id);

        if ((existingChannelIndex === -1 || messageResponse.messages.length === 0) && !this.channels.includes(newChannel)) {
            // If not found and list is full, remove the least recent channel
            if (this.channels.length >= 30) {
                this.channels.pop(); // Remove the last channel (least recent)
            }

            // Add the new channel to the top of the list
            this.channels.unshift(newChannel);
        }
        this.renderChannelList(document.getElementsByClassName('channel-list')[0]);
    }

    renderChannelList(element) {
        const channelListHtml = element
        const selectedChat = channelListHtml.getElementsByClassName('selected-chat')
        let selectedChatId = ''
        if (selectedChat.length > 0) {
            selectedChatId = selectedChat[0].id
        }
        channelListHtml.innerHTML = ''

        this.channels.map((channel) => {
            const otherMembers = this.otherMembers(channel)
            this.displayChats(channelListHtml, otherMembers, channel, selectedChatId)
        })
    }


    displayChats(element, otherMembers, channel, selectedChatId) {
        const messages = channel.state.messageSets[0].messages
        const mostRecentMessage = messages[messages.length - 1]

        let mostRecentMessageSentAt;

        if (!!mostRecentMessage) {
            const mostRecentMessageIsToday = this.messageSentAt(mostRecentMessage).hasSame(DateTime.now(), 'day')
            if (mostRecentMessageIsToday) {
                mostRecentMessageSentAt = this.messageSentAt(mostRecentMessage).toLocaleString(DateTime.TIME_24_SIMPLE)
            } else {
                mostRecentMessageSentAt = this.messageSentAt(mostRecentMessage).toFormat('dd.LL.y')
            }
        } else {
            mostRecentMessageSentAt = ''
        }

        const profilePicturePath = this.getProfilePicturePath(channel, otherMembers)

        element.appendChild(
            MustacheHelper.toElement(
                'components--employee-zone--chat--index--mustache-template--chat-list-item',
                {
                    initials: [this.mapMemberProperty(otherMembers, 'firstname').charAt(0), this.mapMemberProperty(otherMembers, 'lastname').charAt(0)].join(''),
                    title: this.getTitle(channel, otherMembers),
                    firstName: this.mapMemberProperty(otherMembers, 'firstname'),
                    lastName: this.mapMemberProperty(otherMembers, 'lastname'),
                    profilePicturePath: profilePicturePath,
                    hasProfilePicture: profilePicturePath !== '',
                    taskCount: this.taskCount(otherMembers),
                    function: this.mapMemberProperty(otherMembers, 'function'),
                    singleTask: this.taskCount(otherMembers) === 1,
                    functionColor: this.mapMemberProperty(otherMembers, 'functionColor'),
                    channelId: channel.id,
                    mostRecentMessage: mostRecentMessage?.text,
                    mostRecentMessageSentAt: mostRecentMessageSentAt,
                    isActiveText: this.getLastSeen(otherMembers),
                    isActive: this.checkIfOnline(otherMembers),
                    activeState: this.activeState(otherMembers),
                    isSelectedChat: channel.id === selectedChatId
                }
            )
        )
    }

    displayChatMessage(element, message) {
        const member = message.user
        const profilePicturePath = member.profilePicturePath

        const messageDate = new Date(message.created_at);

        const optionsMonth = { month: 'short' }; // Get the short month name
        const optionsDay = { day: '2-digit' }; // Get the day as two digits

        const month = messageDate.toLocaleDateString('de-CH', optionsMonth);
        const day = messageDate.toLocaleDateString('de-CH', optionsDay);

        const formattedDate = `${month} ${day}`; // "Sep 04"

        element.appendChild(
            MustacheHelper.toElement(
                'components--employee-zone--chat--channel-search--show--mustache-template--message-card',
                {
                    initials: [member.firstname.charAt(0), member.lastname.charAt(0)].join(''),
                    fullName: member.name,
                    messageDate: formattedDate,
                    message: message.html,
                    profilePicturePath: profilePicturePath,
                    hasProfilePicture: profilePicturePath !== '' && profilePicturePath !== '#',
                    messageId: message.id
                }
            )
        )
    }

    otherMembers(channel) {
        return (({[this.streamChatUserIdValue]: _, ...rest}) => rest)(channel.state.members);
    }

    mapMemberProperty(otherMembers, property) {
        if (!otherMembers) return '';
        return Object.values(otherMembers)
            .map(member => member.user[property])
            .filter(value => value) // Filter out empty values
            .join(', ');
    }

    taskCount(otherMembers) {
        if (otherMembers === undefined) {
            return ''
        }
        return Object.values(otherMembers).map(member => member.user.taskCount).reduce((sum, count) => sum + count, 0);
    }


    checkIfOnline(otherMembers) {
        if (!otherMembers) return '';

        // Reuse mapMemberProperty to check if users are online
        return Object.values(otherMembers)
            .some(member => member.user.online);
    }

    getLastSeen(otherMembers) {
        if (!otherMembers) return '';

        return this.mapMemberProperty(otherMembers, 'last_active')
            .split(', ')
            .map(lastActive => {
                // Convert valid dates and skip invalid ones
                if (lastActive) {
                    const date = new Date(lastActive);
                    if (!isNaN(date)) {
                        return date.toLocaleString(); // Format the date
                    }
                }
                return ''; // Return empty string if lastActive is invalid or empty
            })
            .filter(Boolean) // Remove empty strings
            .join(', ');
    }

    activeState(otherMembers) {
        if (this.checkIfOnline(otherMembers) === true) {
            return 'online';
        } else {
            if (this.getLastSeen(otherMembers) === '') {
                return 'invisible'
            } else {
                return 'offline'
            }
        }
    }

    getTitle(channel, otherMembers) {
        return channel.data.name || this.mapMemberProperty(otherMembers, 'name')
    }

    getProfilePicturePath(channel, otherMembers) {
        return channel.data.image || this.mapMemberProperty(otherMembers, 'profilePicturePath')
    }

    messageSentAt(message) {
        let sentAt;

        switch (typeof message.created_at) {
            case 'object':
                sentAt = message.created_at.toISOString()
                break
            case 'string':
                sentAt = message.created_at
                break
        }

        return DateTime.fromISO(sentAt)
    }
}
