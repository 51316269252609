import { Controller } from "@hotwired/stimulus"
import IMask from "imask";


export default class extends Controller {
    static targets = ['input']
    connect() {
        this.maskOptions = {
            mask: Number,
            min: 0,
            max: 100,
            scale: 0,
        };

        this.mask = IMask(this.inputTarget, this.maskOptions);
    }
}